<script>
import Layout from "./../layouts/main";
import appConfig from "../../app.config";

export default {
  page: {
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
    };
  },
  name: "FAQs",
  components: {
    Layout
  },
};
</script>

<template>
  <Layout>
      <div class="row">
            <div class="col-lg-12">
                <div class="card rounded-0 bg-soft-success mx-n4 mt-n4 border-top">
                    <div class="px-4">
                        <div class="row">
                            <div class="col-xxl-5 align-self-center">
                                <div class="py-4">
                                    <h4 class="display-6 coming-soon-text">{{ $t("t-faq-title") }}</h4>
                                    <p class="text-success fs-15 mt-3">{{ $t("t-faq-description") }}</p>
<!--                                    <div class="hstack flex-wrap gap-2">-->
<!--                                        <button type="button" class="btn btn-primary btn-label rounded-pill"><i class="ri-mail-line label-icon align-middle rounded-pill fs-16 me-2"></i> Email Us</button>-->
<!--                                    </div>-->
                                </div>
                            </div>
                            <div class="col-xxl-3 ms-auto">
                                <div class="mb-n5 pb-1 faq-img d-none d-xxl-block">
                                    <img src="@/assets/images/faq-img.png" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end card body -->
                </div>
                <!-- end card -->

                <div class="row justify-content-evenly">
                    <div class="col-lg-4">
                      <div class="mt-3">
                        <div class="d-flex align-items-center mb-2">
                          <div class="flex-shrink-0 me-1">
                            <i class="ri-question-line fs-24 align-middle text-success me-1"></i>
                          </div>
                          <div class="flex-grow-1">
                            <h5 class="fs-16 mb-0 fw-semibold">{{ $t("t-faq-group1_title") }}</h5>
                          </div>
                        </div>

                        <div class="accordion accordion-border-box" id="manageaccount-accordion1">
                          <div v-for="index in 6" :key="index" class="accordion-item">
                            <h2 class="accordion-header" :id="'manageaccount-heading1' + index">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#manageaccount-collapse1' + index" aria-expanded="false" :aria-controls="'#manageaccount-collapse1' + index">
                                {{ $t("t-faq-g1-q"+index) }}
                              </button>
                            </h2>
                            <div :id="'manageaccount-collapse1' + index" class="accordion-collapse collapse" :aria-labelledby="'manageaccount-heading1' + index" data-bs-parent="#manageaccount-accordion1">
                              <div class="accordion-body">
                                {{ $t("t-faq-g1-a"+index) }}
                              </div>
                            </div>
                          </div>
                        </div><!--end accordion-->
                      </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="mt-3">
                          <div class="d-flex align-items-center mb-2">
                                <div class="flex-shrink-0 me-1">
                                    <i class="ri-exchange-line fs-24 align-middle text-success me-1"></i>
                                </div>
                                <div class="flex-grow-1">
                                    <h5 class="fs-16 mb-0 fw-semibold">{{ $t("t-faq-group2_title") }}</h5>
                                </div>
                            </div>

                            <div class="accordion accordion-border-box" id="manageaccount-accordion2">
                                <div v-for="index in 13" :key="index" class="accordion-item">
                                    <h2 class="accordion-header" :id="'manageaccount-heading2' + index">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#manageaccount-collapse2' + index" aria-expanded="false" :aria-controls="'#manageaccount-collapse2' + index">
                                          {{ $t("t-faq-g2-q"+index) }}
                                        </button>
                                    </h2>
                                    <div :id="'manageaccount-collapse2' + index" class="accordion-collapse collapse" :aria-labelledby="'manageaccount-heading2' + index" data-bs-parent="#manageaccount-accordion2">
                                        <div class="accordion-body">
                                          {{ $t("t-faq-g2-a"+index) }}
                                        </div>
                                    </div>
                                </div>
                            </div><!--end accordion-->
                        </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="mt-3">
                        <div class="d-flex align-items-center mb-2">
                          <div class="flex-shrink-0 me-1">
                            <i class="ri-pie-chart-2-line fs-24 align-middle text-success me-1"></i>
                          </div>
                          <div class="flex-grow-1">
                            <h5 class="fs-16 mb-0 fw-semibold">{{ $t("t-faq-group3_title") }}</h5>
                          </div>
                        </div>

                        <div class="accordion accordion-border-box" id="manageaccount-accordion3">
                          <div v-for="index in 5" :key="index" class="accordion-item">
                            <h2 class="accordion-header" :id="'manageaccount-heading3' + index">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#manageaccount-collapse3' + index" aria-expanded="false" :aria-controls="'#manageaccount-collapse3' + index">
                                {{ $t("t-faq-g3-q"+index) }}
                              </button>
                            </h2>
                            <div :id="'manageaccount-collapse3' + index" class="accordion-collapse collapse" :aria-labelledby="'manageaccount-heading3' + index" data-bs-parent="#manageaccount-accordion3">
                              <div class="accordion-body">
                                {{ $t("t-faq-g3-a"+index) }}
                              </div>
                            </div>
                          </div>
                        </div><!--end accordion-->
                      </div>
                    </div>
                </div>
            </div><!--end col-->.
        </div><!--end row-->
    </Layout>
</template>
